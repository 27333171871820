document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);

    const animateCards = () => {
      gsap.from(".char-img", {
        scrollTrigger: {
          trigger: ".block-character-casino",
          start: "top 80%",
        },
        y: 50,
        opacity: 0,
        stagger: 0.2,
        duration: 1,
        ease: "power2.out",
      });
    };

    animateCards();
});
